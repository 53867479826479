<template>
  <div class="p-grid p-fluid dashboard">
    <div v-if="isAdmin" class="p-col-12 p-lg-4">
      <user-count-widget :count="userCount" />
    </div>
    <div class="p-col-12 p-lg-4">
      <time-remain-widget :wage="wage" />
    </div>
    <div class="p-col-12 p-lg-4">
      <storage-widget :wage="wage" />
    </div>
    <div class="p-col-12 p-lg-4">
      <process-count-widget :count="processCount" />
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";

import ProcessCountWidget from "../../components/dashboard/ProcessCountWidget";
import StorageWidget from "../../components/dashboard/StorageWidget";
import TimeRemainWidget from "../../components/dashboard/TimeRemainWidget";
import UserCountWidget from "../../components/dashboard/UserCountWidget";
import { Role } from "../../models/Role";
import {
  FETCHING_ACCOUNT,
  FETCHING_LAST_BALANCE,
} from "../../store/modules/account/account.actions.type";
import {
  GET_ACCOUNT,
  GET_LAST_BALANCE,
} from "../../store/modules/account/account.getters.type";
import { GET_PROFILE } from "../../store/modules/auth/auth.getters.type";
import { COUNT_PROCESSES } from "../../store/modules/process/process.getters.type";
import { COUNT_USERS } from "../../store/modules/user/user.getters.type";

export default {
  name: "Dashboard",
  components: {
    StorageWidget,
    TimeRemainWidget,
    ProcessCountWidget,
    UserCountWidget,
  },
  setup() {
    const store = useStore();
    const profile = computed(() => store.getters[GET_PROFILE]);
    const isAdmin = profile.value.hasRole(Role.admin);
    const userCount = computed(() => store.getters[COUNT_USERS]);
    const processCount = computed(() => store.getters[COUNT_PROCESSES]);
    const wage = computed(() => store.getters[GET_LAST_BALANCE]);

    onMounted(() => {
      if (store.getters[GET_ACCOUNT] === null) {
        store.dispatch(FETCHING_ACCOUNT);
      }
      store.dispatch(FETCHING_LAST_BALANCE);
    });

    return {
      isAdmin,
      userCount,
      processCount,
      wage,
    };
  },
};
</script>

<style scoped></style>
