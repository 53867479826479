<template>
  <div class="card summary">
    <span class="title">{{ $t("dashboard.storage.title") }}</span>
    <span v-if="wage" class="detail" data-testid="description">{{
      $t("dashboard.storage.description", { msg: message })
    }}</span>
    <span v-if="wage" class="count storage" data-testid="percentage"
      >{{ wage.availableSpacePercentage }}%</span
    >
    <Skeleton v-else height="3rem" width="5rem" class="count storage" />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import Skeleton from "primevue/skeleton";
import { useI18n } from "vue-i18n";

import { storageTransformer } from "../../service/utils";

export default {
  name: "StorageWidget",
  components: {
    Skeleton,
  },
  props: {
    wage: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const message = computed(() => storage(props.wage));

    const storage = (wage) => {
      if (wage == null) {
        return t("dashboard.shared.loading");
      }
      return `${storageTransformer.humanReadable(
        wage.usedSpace
      )} / ${storageTransformer.humanReadable(wage.maxSpace)}`;
    };

    return {
      message,
    };
  },
};
</script>
