<template>
  <div class="card summary">
    <span class="title">{{ $t("dashboard.process.title") }}</span>
    <span class="detail">{{ $t("dashboard.process.description") }}</span>
    <span v-if="count != null" class="count processes" data-testid="count">{{
      count
    }}</span>
    <Skeleton v-else height="3rem" width="3rem" class="count processes" />
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "ProcessCountWidget",
  components: {
    Skeleton,
  },
  props: {
    count: {
      type: Number,
      default: null,
    },
  },
};
</script>
