<template>
  <div class="card summary">
    <span class="title">{{ $t("dashboard.time.title") }}</span>
    <span v-if="wage" class="detail" data-testid="description">{{
      $t("dashboard.time.description", { msg: wage.timeRemainInMinutes() })
    }}</span>
    <span v-if="wage" class="count time" data-testid="percentage"
      >{{ wage.remainTimePercentage }}%</span
    >
    <Skeleton v-else height="3rem" width="5rem" class="count time" />
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "TimeRemainWidget",
  components: {
    Skeleton,
  },
  props: {
    wage: {
      type: Object,
      default: null,
    },
  },
};
</script>
